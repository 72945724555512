<template>
	<div id="scroll_container">
		<ul role="tablist" class="nav nav-tabs SCROLL outer" v-hscroll>
			<li v-for="item in values" :key="item.key" class="nav-item" role="presentation">
				<a role="button" class="nav-link" :class="selected == item.key ? 'active' : ''"
					@click="handleInput(item.key)">
					<i :class="item.icon" class="mr-2 top-plus-2" v-if="item.icon"></i>
					{{ item.text }}
				</a>
			</li>
		</ul>

	</div>
</template>

<script>
import HScroll from "@koas/v-hscroll";

export default {
	props: {
		values: {
			type: Array,
			default: null
		},
		value: {
			default: null
		},
	},
	watch: {
		value: function (newValue) {
			this.selected = newValue;
		}
	},
	data() {
		return {
			selected: null,
		}
	},
	mounted() {
		const scroolContainer = document.querySelector('#scroll_container')
		document.addEventListener('wheel', (e) => {
			if (scroolContainer)
				scroolContainer.scrollLeft += e.deltaY;
		})
	},
	created() {
		this.selected = this.value;
	},
	methods: {
		handleInput(event) {
			this.selected = event
			this.$emit('input', event)
		}
	}
};
</script>
<style type="text/css">
.inner {
	overflow: auto;
	white-space: nowrap;
	border: 1px solid #000;
}

.element {
	width: 250px;
	height: 150px;
	background: #000;
	color: #fff;
	margin: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
</style>