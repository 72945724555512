<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_registration_reservation')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_registration_reservation')"
                              :isFilter="false"/>
            </template>
            <div v-if="systemStatus=='success'">
                <b-row>
                    <b-col cols="12" md="4" lg="4" xl="3">
                        <h6 class="mb-2">
                            {{ student.student_number ? student.student_number : '-' }} -
                            {{ student.name ? student.name : '' }} {{ student.surname ? student.surname : '' }}
                        </h6>
                        <div class="border rounded p-3 mb-1 mb-md-5">
                            <div class="mb-1">
                                {{ this.getLocaleText(student, 'program_name') }}
                            </div>
                            <div>
                                {{ this.getLocaleText(student, 'class_name') }}
                                {{
                                    student.number_of_semesters ? ' - ' + $t('semester_x', {semester: student.number_of_semesters}) : ''
                                }}
                            </div>
                            <div>
                                {{ getLocaleText(student, 'success_status_name', '-') }}
                            </div>
                        </div>
                        <div class="border rounded p-3 mb-1 mb-md-5">
                            <b-form-group :label="$t('gno')" class="mb-0">
                                <div class="label-as-input">
                                    {{ student.cgpa ? student.cgpa : '-' }}
                                </div>
                            </b-form-group>
                        </div>
                        <div class="border rounded p-3 text-center mb-2">
                            <b-row>
                                <b-col cols="4" md="6">
                                    <b-form-group :label="$t('credits_attempted')" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.sum_of_credits ? student.sum_of_credits : 0 }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" md="6">
                                    <b-form-group :label="$t('ects_credits_attempted')" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.sum_of_ects_credits ? student.sum_of_ects_credits : 0 }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" md="6">
                                    <b-form-group :label="$t('number_of_courses_taken')" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.count_of_courses ? student.count_of_courses : 0 }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="12" md="8" lg="8" xl="9">
                        <h6 class="mb-2">
                            {{ student.academic_year }} - {{ getLocaleText(student, 'semester_name') }}
                        </h6>
                        <div class="border rounded-sm p-4">
                            <b-row>
                                <b-col cols="12">
                                    <course-auto-complete :filter="{exchange:1}" :clear-on-select="true"
                                                          v-on:courseSelected="courseSelected"></course-auto-complete>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" v-if="takenCourses && takenCourses.length">
                                    <div class="mb-2 mt-3">
                                        <h6 class="mb-0 mr-3 d-inline-block mb-3">{{ $t('your_chosen_courses') }}</h6>
                                        <div>
                                            <b-button :variant="showType=='list' ? 'primary':'outline-primary'"
                                                      class="mr-1 d-inline-block" @click="showType='list'">
                                                <i class="ri-file-list-2-line mr-1"></i> {{ this.toUpperCase('list') }}
                                            </b-button>
                                            <b-button :variant="showType=='calendar' ? 'primary':'outline-primary'"
                                                      class="d-inline-block" @click="showType='calendar'">
                                                <i class="ri-calendar-todo-fill mr-1"></i>
                                                {{ this.toUpperCase('course_schedule') }}
                                            </b-button>
                                        </div>
                                    </div>
                                    <div v-if="showType=='list'">
                                        <b-table :empty-filtered-text="$t('no_result')"
                                                 :empty-text="$t('no_result')"
                                                 bordered
                                                 striped
                                                 responsive
                                                 :items="takenCourses"
                                                 :fields="takenCourseFields"
                                                 class="mb-0 table-dropdown no-scrollbar border rounded"
                                        >
                                            <template #cell(dropdownTable)="data">
                                                <b-dropdown variant="link btn-sm" boundary="window" ref="dropdown"
                                                            no-caret>
                                                    <template #button-content>
                                                        <i class="ri-more-fill"></i>
                                                    </template>
                                                    <div id="dropdownList" v-if="!data.item.is_locked">
                                                        <b-dropdown-item
                                                            v-if="!data.item.is_locked && data.item.course_id && data.item.allow_section_change==true && data.item.section_number"
                                                            @click="getSectionList(data.item.course_id, data.item.section_id,1)">
                                                            <span>
                                                                <i class="ri-pencil-fill align-middle top-minus-1 mr-3 text-muted"></i>
                                                                {{ $t('theoretical') }} - {{ $t('change_section') }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            v-if="data.item.course_id && data.item.allow_section_change==true && data.item.lab_section_number"
                                                            @click="getSectionList(data.item.course_id, data.item.section_id,2)">
                                                            <span>
                                                                <i class="ri-pencil-fill align-middle top-minus-1 mr-3 text-muted"></i> {{
                                                                    $t('lab')
                                                                }} - {{ $t('change_section') }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            @click="deleteCourse(data.item.student_slot_id, data.item.course_id)">
                                                            <span>
                                                                <i class="ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted"></i>
                                                                {{ $t('delete') }}
                                                            </span>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                            </template>
                                            <template #cell(slot_type)="data">
                                                {{ getLocaleText(data.item, 'slot_type_text') }}
                                            </template>
                                            <template #cell(credit)="data">
                                                {{ data.item.credit ? parseInt(data.item.credit) : '-' }}
                                            </template>
                                            <template #cell(ects_credit)="data">
                                                {{ data.item.ects_credit ? parseInt(data.item.ects_credit) : '-' }}
                                            </template>
                                            <template #cell(course_name)="data">
                                                {{ getLocaleText(data.item, 'course_name') }}
                                            </template>
                                        </b-table>
                                    </div>
                                    <div v-if="showType=='calendar'">
                                        <time-table :takenSectionIds="takenSectionIds"></time-table>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>

                <CommonModal ref="changeSectionModal" :onHideOnlyX="true" size="lg">
                    <template v-slot:CommonModalTitle>
                        {{ $t('change').toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <div v-if="changeSectionList">
                            <div class="d-flex">
                                <div class="flex-grow-1 mr-2">
                                    <b-form-select v-model="changeSectionSelect">
                                        <option v-for="list in changeSectionList" :value="list.id" :key="list.id">
                                            {{ list.section }}
                                            {{
                                                list.instructor ? ' - ' + list.instructor.name + ' ' + list.instructor.surname : ''
                                            }}
                                            {{ '- ' + $t('remaining_quota') + ': ' + list.remaining_quota }}
                                            {{
                                                (list.course_schedules || []).length ?
                                                    ('- ' + $t('date') + ': ' + getDayText(list.course_schedules[0].day) + '- ' + $t('start_time') + ':' + getObjectValue(list.course_schedules[0], 'start_time'))
                                                    : ''
                                            }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <div>
                                    <b-button variant="primary"
                                              @click="changeSection(changeSectionCourseId, changeSectionSelect,changeSectionType)">
                                        {{ $t('save') }}
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t('no_result') }}
                        </div>
                    </template>
                </CommonModal>

                <CommonModal id="selectSectionModal" :onHideOnlyX="true" size="lg">
                    <template v-slot:CommonModalTitle v-if="course">
                        {{ course.code }} - {{ getLocaleText(course, 'name') }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <div v-if="sectionList">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <div v-if="sectionList[1]">
                                        <h5>{{ $t('theoretical') }}</h5>
                                        <table class="table table-bordered">
                                            <tr>
                                                <th class="width-80">{{ $t('section') }}</th>
                                                <th>{{ $t('instructor') }}</th>
                                                <th class="width-200">{{ $t('course_schedule') }}</th>
                                            </tr>
                                            <tr v-for="list in sectionList[1]">
                                                <td>
                                                    <b-form-checkbox v-model="sectionId" :value="list.id">
                                                        {{ list.section }}
                                                    </b-form-checkbox>
                                                </td>
                                                <td>
                                                    {{
                                                        list.instructor ? list.instructor.name + ' ' + list.instructor.surname : ''
                                                    }}
                                                </td>
                                                <td>
                                                    <ul v-if="list.course_schedules.length>0">
                                                        <li v-for="schedule in list.course_schedules">
                                                            {{getDayText(schedule.day)}} {{schedule.start_time}} - {{schedule.end_time}}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div v-if="sectionList[2]">
                                        <br>
                                        <h5>{{ $t('practical') }}</h5>
                                        <table class="table table-bordered" v-if="sectionList[2]">
                                            <tr>
                                                <th class="width-80">{{ $t('section') }}</th>
                                                <th>{{ $t('instructor') }}</th>
                                                <th class="width-200">{{ $t('course_schedule') }}</th>
                                            </tr>
                                            <tr v-for="list in sectionList[2]">
                                                <td>
                                                    <b-form-checkbox v-model="labSectionId" :value="list.id">
                                                        {{ list.section }}
                                                    </b-form-checkbox>
                                                </td>
                                                <td>
                                                    {{
                                                        list.instructor ? list.instructor.name + ' ' + list.instructor.surname : ''
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        (list.course_schedules || []).length ?
                                                            ('- ' + $t('date') + ': ' + getDayText(list.course_schedules[0].day) + '- ' + $t('start_time') + ':' + getObjectValue(list.course_schedules[0], 'start_time'))
                                                            : ''
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <b-button variant="primary"
                                          @click="selectSection()">
                                    {{ $t('save') }}
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t('no_result') }}
                        </div>
                    </template>
                </CommonModal>
            </div>
            <div v-else-if="systemStatus=='error'">
                <warning-page :title="$t('information')" :message="systemStatusMessage"/>
            </div>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

// Component
import TabButton from '@/components/elements/TabButton.vue';
import CommonModal from '@/components/elements/CommonModal';

// Page
import TimeTable from './TimeTable';
import WarningPage from '@/components/page/WarningPage';

// Services
import CourseRegistration from '@/services/CourseRegistrationReservation';
import qs from 'qs';

export default {
    name: "CourseRegistrationExchange",
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        TabButton,
        TimeTable,
        CommonModal,
        WarningPage
    },
    metaInfo() {
        return {
            title: this.$t('course_registration_reservation')
        }
    },
    data() {
        return {
            // Student
            studentProgramId: null,
            student: [],

            course: null,
            sectionId: null,
            labSectionId: null,
            sectionList: null,
            // System
            systemStatus: null,
            systemStatusMessage: null,

            // Show Type
            showType: 'list',

            // Taken
            takenCourses: [],
            takenSectionIds: [],
            takenCourseFields: [
                {key: 'dropdownTable', label: ' ', class: 'p-0 text-center w-40 align-middle'},
                {
                    key: 'course_code',
                    label: this.$t('course_code').toUpper(),
                    sortable: true,
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'course_name',
                    label: this.$t('course').toUpper(),
                    sortable: true,
                    tdClass: 'text-left',
                    thClass: 'text-left'
                },
                {
                    key: 'section_number',
                    label: this.$t('section').toUpperCase(),
                    sortable: true,
                    tdClass: 'text-left',
                    thClass: 'text-left'
                },
                {
                    key: 'lab_section_number',
                    label: this.$t('lab_section').toUpperCase(),
                    sortable: true,
                    tdClass: 'text-left',
                    thClass: 'text-left'
                },
                {
                    key: 'credit',
                    label: this.$t('credit').toUpper(),
                    sortable: true,
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'ects_credit',
                    label: this.$t('ects').toUpper(),
                    sortable: true,
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: this.getLocaleField('repeated_course.course_name'),
                    label: this.$t('last_course'),
                    sortable: true
                },
                {key: 'repeated_course.letter_grade', label: this.$t('letter_grade'), sortable: true}
            ],
            creditLimit: 0,
            creditTotal: 0,

            changeSectionCourseId: null,
            changeSectionSelect: null,
            changeSectionType: null,
            changeSectionList: [],
        }
    },
    mounted() {
        this.loading = true;

        this.studentProgramId = this.$route.params.id;

        if (this.studentProgramId !== undefined) {
            this.getExchange()
                .then(() => {
                    this.loading = false;
                })
        } else {
            this.getFromUser()
                .then(() => {
                    this.getExchange()
                        .then(() => {
                            this.loading = false;
                        })
                })
        }
    },
    methods: {
        async getFromUser() {
            let user = await this.$store.getters['auth/getUser'];
            if (user && user.active_student_program && user.active_student_program.id) {
                this.studentProgramId = user.student.id;
            } else {
                this.$router.push('/404')
            }

        },
        async getExchange() {
            this.systemStatus = null;
            await CourseRegistration.getExchange(this.studentProgramId)
                .then(response => {
                    this.systemStatus = 'success';

                    this.student = response.data.data.student;

                    this.takenCourses = [];
                    this.takenCourses = response.data.data.taken_courses;
                    this.takenSectionIds = response.data.data.taken_section_id;
                })
                .catch(e => {
                    if(e.data.message=='ONLY_EXCHANGE_STUDENTS'){
                        this.$router.push('/course/registration/reservation/'+this.studentProgramId);
                    }
                    this.systemStatus = 'error';
                    this.systemStatusMessage = this.getErrorMessage(e.data);
                })
        },

        courseSelected(course) {
            this.course = course.item;
            this.getCourseSectionList(course.value);
        },

        getCourseSectionList(courseId) {
            this.sectionList = [];
            CourseRegistration.getExchangeCourseSections(courseId)
                .then(response => {
                    this.sectionList = response.data.data;
                    this.$bvModal.show('selectSectionModal');
                })
                .catch(e => this.showErrors(e));
        },
        selectSection() {
            if (!this.sectionId) {
                this.$toast.error(this.$t('select_theoretical_section'));
                return;
            }
            if (this.sectionList && this.sectionList[2] && !this.labSectionId) {
                this.$toast.error(this.$t('select_practical_section'));
                return;
            }

            let formData = {
                student_program_id: this.studentProgramId,
                course_id: this.course.id,
                section_id: this.sectionId
            };
            if (this.labSectionId) {
                formData.lab_section_id = this.labSectionId;
            }
            CourseRegistration.storeExchange(formData)
                .then(response => {
                    this.getExchange();
                    this.showMessage(response);
                    this.course = null;
                    this.sectionId = null;
                    this.labSectionId = null;
                    this.$bvModal.hide('selectSectionModal');
                }).catch(e => this.showErrors(e));
        },

        // Section Change
        getSectionList(courseId, selected, type) {
            this.changeSectionCourseId = courseId;
            this.changeSectionSelect = selected;
            this.changeSectionType = type;
            const config = {
                params: {
                    'type': type
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            CourseRegistration.getChangeSection(this.studentProgramId, courseId, config)
                .then(response => {
                    this.changeSectionList = response.data.data;
                })
                .then(response => {
                    this.$refs.changeSectionModal.$refs.commonModal.show()
                });
        },
        changeSection(courseId, sectionId, type) {
            CourseRegistration.putChangeSection(this.studentProgramId, courseId, sectionId, type)
                .then(response => {
                    this.$toast.success(this.$t('successfully_changed'));
                    this.$refs.changeSectionModal.$refs.commonModal.hide();
                    this.getExchange();
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        // Delete
        deleteCourse(slotId, courseId) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        CourseRegistration.delExchange(this.studentProgramId, slotId, courseId)
                            .then(response => {
                                this.$toast.success(this.$t('successfully_deleted'));
                                this.getExchange();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
    }
}
</script>
